import React from "react";
import "./layout.sass";

export default function Layout({children}){
	return(
		<div className="wholeScreen">
			<div className="verticalAndHorizontalCenter">
				{children}
			</div>
		</div>
	)
}

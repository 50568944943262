import React from "react";
import Layout from "../components/layout";

export default function Home(){
	return(
		<Layout>
			<h1> <strong>CHCHStudios</strong> is coming soon! </h1>
		</Layout>
	)
}
